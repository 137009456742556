import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <g transform="translate(11.000000,65.000000) scale(0.025,-0.026)" fill="currentColor">
          <path
            d="M983 1582 l-92 -167 44 -80 c24 -44 195 -352 379 -685 l335 -605 227
-3 c193 -2 225 0 221 12 -3 8 -206 376 -450 818 -245 441 -453 820 -463 841
-17 35 -20 37 -63 37 l-46 0 -92 -168z"
          />
          <path
            d="M474 663 c-148 -268 -286 -518 -307 -555 l-38 -68 228 0 228 0 193
348 c105 192 192 352 192 356 0 7 -220 406 -224 406 -1 0 -123 -219 -272 -487z"
          />
        </g>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
